<template>
  <div class="pb-8">
    <HeaderView></HeaderView>
    <div class="fixed top-16 left-[10%] z-[100]">
      <HistoryBack :disableAction="true" @backParent="goBack" />
    </div>
    <div class="custom-gradient text-center text-2xl not-italic font-semibold md:pb-2 sm:pb-2 md:mb-6 sm:mb-2 mt-16">
      <span v-if="booking.type?.code == bookingType.MATCH">{{ $t("match_details") }}</span>
      <span v-else>{{ $t("booking_details") }}</span>
    </div>
    <div class="flex items-center mt-8 mb-6 sm:text-sm"></div>
    <div class="paymentBoxLeft md:p-6 sm:pt-4 sm:mb-6 relative paymentWrapper">
      <BookingDetail
        v-if="booking.id !== undefined"
        :booking="booking"
        :showImage="false"
        :showChat="user.id !== 0"
        :showOpenFacility="false"
        :showResourceName="true"
        class="paymentBoxLeft p-6 pt-8 md:mr-6"
      ></BookingDetail>
      <div class="paymentBoxRight md:p-6 sm:pt-4 sm:mb-6 relative">
        <AddPlayers
          v-if="booking.id !== undefined"
          :showTitle="false"
          :bookingData="booking"
          :mode="mode.RESULTS"
          :active="movePlayers"
        ></AddPlayers>
      </div>
    </div>

    <div
      v-if="showMainResults && !isSoccer()"
      class="scoreScope mb-6 p-2 br16 overflow-x-auto"
    >
      <div class="pr-6 mb-5">
        <div>{{ $t("score") }}</div>
      </div>
      <span class="capitalize"> {{ getTeamNames(team.TEAM1) }} </span>
      <div class="flex">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="items-center w-full scoreResult text-xs text-center text-gray-400"
        >
          <span> {{ $t("set") }} {{ i }} </span>
        </div>
      </div>
      <div
        class="flex scoreBox br8 noBorderB"
        @click="
          validNumberPlayers && allResults.length < 1
            ? (savingResults = true)
            : (savingResults = false)
        "
      >
        <div
          class="flex items-center borderl justify-center w-full scoreResult"
          v-for="i in maxSportSets"
          :key="i"
        >
          <input
            v-model="resultsTeam1[i - 1]"
            class="bg-transparent"
            :disabled="
              !validNumberPlayers || allResults.length > 1 || !userIsPlayer
            "
          />
        </div>
      </div>
      <div
        class="flex scoreBox br8 noBorderT"
        @click="
          validNumberPlayers && allResults.length < 1
            ? (savingResults = true)
            : (savingResults = false)
        "
      >
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="flex items-center borderl justify-center w-full scoreResult"
        >
          <input
            v-model="resultsTeam2[i - 1]"
            class="bg-transparent"
            :disabled="
              !validNumberPlayers || allResults.length > 1 || !userIsPlayer
            "
          />
        </div>
      </div>
      <span class="capitalize"> {{ getTeamNames(team.TEAM2) }}</span>
      <div class="flex">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="items-center w-full scoreResult text-xs text-center text-gray-400"
        >
          <span>{{ $t("set") }} {{ i }} </span>
        </div>
      </div>
    </div>
    <!-- EDIT --->
    <div
      v-if="showEditResults"
      class="scoreScope mb-6 p-2 br16 overflow-x-auto"
    >
      <div class="pr-6 mb-5">
        <div>{{ $t("score") }} ({{ $t("edited") }})</div>
      </div>
      <span class="capitalize"> {{ getTeamNames(team.TEAM1) }}</span>
      <div class="flex">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="items-center w-full scoreResult text-xs text-center text-gray-400"
        >
          <span> {{ $t("set") }} {{ i }} </span>
        </div>
      </div>
      <div class="flex scoreBox br8 noBorderB">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="flex items-center borderl justify-center w-full scoreResult"
        >
          <input
            type="number" min="0" :max="maxSetPoints"
            v-model="editedResultsTeam1[i - 1]"
            placeholder="0"
            class="bg-transparent"
            :disabled="allResults.length > 1 || !userIsPlayer"
          />
        </div>
      </div>
      <div class="flex scoreBox br8 noBorderT">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="flex items-center borderl justify-center w-full scoreResult"
        >
          <input
            type="number" min="0" :max="maxSetPoints"
            v-model="editedResultsTeam2[i - 1]"
            placeholder="0"
            class="bg-transparent"
            :disabled="allResults.length > 1 || !userIsPlayer"
          />
        </div>
      </div>
      <div class="flex">
        <div
          v-for="i in maxSportSets"
          :key="i"
          class="items-center w-full scoreResult text-xs text-center text-gray-400"
        >
          <span> {{ $t("set") }} {{ i }} </span>
        </div>
      </div>
      <span class="capitalize"> {{ getTeamNames(team.TEAM2) }}</span>
    </div>
  </div>
  <div id="savingResults" v-if="savingResults" class="scoreButtons pb-6">
    <input
      class="search-btn mb-4"
      type="button"
      :value="$t('save')"
      :disabled="hasErrors"
      :class="{ 'button-disabled': hasErrors, '': true }"
      @click="saveResults()"
    />
    <input
      @click="cancelSaveResults()"
      class="paymentOption cursor-pointer flex items-center p-6 justify-center"
      type="button"
      :value="$t('cancel')"
    />
  </div>
  <div id="feet"
    v-if="allResults.length < 2 && !savingResults && userIsPlayer"
    @click="
      showEditResults = true;
      savingResults = true;
    "
    class="flex justify-center items-center custom-gradient cursor-pointer pb-8">
    <span v-if="allResults.length == 1">{{ $t("not_right_question") }}&nbsp;</span> {{ $t("click_here_to_change_it") }}
  </div>
  <div v-if="isIos" class="h-24">

  </div>
</template>

<script lang="ts">
import HeaderView from "../components/header/header.vue";
import { Booking } from "@/custom-models/booking";
import {
  BookingTextStatus,
  PlayersMode,
  PlayerType,
  Sport,
  Team,
  BookingType,
  MaxSportSets,
  MaxSetPoints
} from "@/enum/constants";
import AddPlayers from "@/components/booking/addPlayers.vue";
import BookingDetail from "../components/booking/bookingDetail.vue";
import { defineComponent, nextTick } from "vue";
import { BookingApi } from "@/service/BookingApi";
import { customGlobalError } from "@/helpers/errorHelper";
import store from "../store";
import { BookingHelper } from "@/helpers/bookingHelper";
import { Format } from "@/helpers/formatHelper";
import { BookingResult, SimpleResult, Sport as SportModel } from "models";
import HistoryBack from "@/components/form/historyBack.vue";
import router from "@/router";
import { AppsHelper } from "@/helpers/appsHelper";


export default defineComponent({
  name: "BookingResultsView",
  components: {
    HeaderView,
    AddPlayers,
    BookingDetail,
    HistoryBack,
  },
  data() {
    return {
      showEditResults: false,
      showMainResults: true,
      validNumberPlayers: false,
      mode: PlayersMode,
      booking: {} as Booking,
      savingResults: false,
      allResults: [] as any[],
      resultsTeam1: [] as any[],
      resultsTeam2: [] as any[],
      editedResultsTeam1: [] as any[],
      editedResultsTeam2: [] as any[],
      user: { id: 0 },
      userIsPlayer: false,
      team: Team,
      errorMessage:"",
      bookingType: BookingType,
      movePlayers: true,
      maxSportSets: 5 as number,
      maxSetPoints: 999 as number,
      isIos:false
    };
  },
  watch: {
    savingResults() {
      nextTick(() => {
        this.fixIphoneHeight();
      });
    }
  },
  computed: {
    hasErrors() {
      if (
        (this.editedResultsTeam1[0] && this.editedResultsTeam1[0] > 0) && 
        (this.editedResultsTeam2[0] && this.editedResultsTeam2[0] > 0)
      ) {
        return false;
      }
      return true;
    }
  },
  created() {
    let user: any = localStorage.getItem("user");
    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
    this.user = JSON.parse(user);
  },
  async mounted() {
    if (window.location.href.includes("results")) {
      document.body.style.overflowY = "scroll";
    }
    document.body.style.background = "none";

    if (Format.IsNull(this.$route.params.id)) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }
    const response = await BookingApi.findByHash1(this.$route.params.id.toString());
    // si la reserva es futura, redireccionar a booking-join/bookingId
    if (response.data.status != null) {
      if (response.data.status.name != BookingTextStatus.FINISHED) {
        this.$router.push("/booking-join/" + this.$route.params.id);
      }
    }
    try {
      // parse server booking format to booking.ts format
      this.booking = BookingHelper.parse(response.data);
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
    }
    this.validateSportResultLimits(response.data.bookingBlocks[0].sport);
    const players: any = this.booking.players;
    this.userIsPlayer = players.some(
      (player: any) => player.customerId === this.user.id
    );

    this.validateNumberPlayers();
    nextTick(() => {
      this.fixIphoneHeight();
    });

    this.allResults = this.booking.results as BookingResult[];
    if (this.allResults === undefined) {
      this.allResults = [];
      return;
    }

    if (this.allResults.length === 0) {
      return;
    }
    let results = null;
    let editedResults = null;
    if (this.allResults.length === 1) {
      results = this.allResults[0];
    }
    if (this.allResults.length > 1) {
      editedResults = this.allResults[0];
      results = this.allResults[1];
    }
    if (results != null) {
      for (let i = 1; i <= 5; i++) {
        this.resultsTeam1.push((results.team1 as any)['value' + i]);
        this.resultsTeam2.push((results.team2 as any)['value' + i]);
      }
    }

    if (editedResults != null) {
      this.showEditResults = true;
      this.showMainResults = false;
      
      for (let i = 1; i <= 5; i++) {
        this.editedResultsTeam1.push((editedResults.team1 as any)['value' + i]);
        this.editedResultsTeam2.push((editedResults.team2 as any)['value' + i]);
      }
      return;
    }
  },
  methods: {
    goBack() {
      const fromRoute = this.$route;
      if (fromRoute.name === "bookingResults") {
        if (window.history.length > 2) {
          this.$router.go(-2);
        } else {
          router.push("/");
        }
        return;
      }
    },
    validateSportResultLimits(sport: SportModel){
      switch (sport.code) {
        case Sport.PADEL:
        case Sport.TENIS:
        case Sport.PICKLEBALL:
            this.maxSportSets = MaxSportSets.DEFAULT_RACKET_SPORT;
            this.maxSetPoints = MaxSetPoints.DEFAULT_RACKET_SPORT;
          break;
        case Sport.SOCCER:
            this.maxSportSets = MaxSportSets.SOCCER;
            this.maxSetPoints = MaxSetPoints.SOCCER;
          break;
        case Sport.PADBOL:
            this.maxSportSets = MaxSportSets.PADBOL;
            this.maxSetPoints = MaxSetPoints.PADBOL;
          break;
        case Sport.RACQUETBALL:
            this.maxSportSets = MaxSportSets.RACQUETBALL;
            this.maxSetPoints = MaxSetPoints.RACQUETBALL;
          break;
        case Sport.SQUASH:
            this.maxSportSets = MaxSportSets.SQUASH;
            this.maxSetPoints = MaxSetPoints.SQUASH;
          break;
      }
    },
    validateNumberPlayers() {
      const players: any = this.booking.players;
      const nPlayers = players.length;
      const activePlayers = players.filter(
        (y: any) => y.type.code !== PlayerType.EMPTY
      );
      this.validNumberPlayers = nPlayers === activePlayers;
    },
    async saveResults() {
      
      var resultsTeam1 = this.resultsTeam1;
      var resultsTeam2 = this.resultsTeam2;
      //fill with 0 all empty values in array, to prevent send error to bakc if user don't put all values
      if (this.showEditResults) {
        resultsTeam1 = Array.from({ length: this.maxSportSets }, (_, index) => {// _ is just a convention to indicate that the value parameter is intentionally ignored.
          if (typeof this.editedResultsTeam1[index] !== 'undefined') {
            return this.editedResultsTeam1[index];
          } else {
            return 0;
          }
        });
        resultsTeam2 = Array.from({ length: this.maxSportSets }, (_, index) => {
          if (typeof this.editedResultsTeam2[index] !== 'undefined') {
            return this.editedResultsTeam2[index];
          } else {
            return 0;
          }
        });
      }

      var team1 = {} as SimpleResult;
      var team2 = {} as SimpleResult;

      team1.value1 = resultsTeam1[0];
      team1.value2 = resultsTeam1[1];
      team1.value3 = resultsTeam1[2];
      team1.value4 = resultsTeam1[3];
      team1.value5 = resultsTeam1[4];

      team2.value1 = resultsTeam2[0];
      team2.value2 = resultsTeam2[1];
      team2.value3 = resultsTeam2[2];
      team2.value4 = resultsTeam2[3];
      team2.value5 = resultsTeam2[4];

      var results: BookingResult = {
        team1: team1,
        team2: team2,
      };
      const response = await BookingApi.addResult(results, this.$route.params.id.toString());
      if (response.status === 201) {
        this.showEditResults = false;
        this.savingResults = false;
        this.allResults = response.data.results;
        this.resultsTeam1 = [];
        this.resultsTeam2 = [];
        results = this.allResults[0];
        if (results != null) {
          for (let i = 1; i <= 5; i++) {
            this.resultsTeam1.push((results.team1 as any)['value' + i]);
            this.resultsTeam2.push((results.team2 as any)['value' + i]);
          }
        }
      }
      if(this.allResults.length == 2){
        this.movePlayers = false;
      }
    },
    cancelSaveResults() {
      this.showEditResults = false;
      this.savingResults = false;
    },
    getTeamNames(team: number) {
      if (this.booking.players === undefined) {
        return;
      }

      return this.booking.players
        .filter((y) => y.team === team && y.type.code !== PlayerType.EMPTY)
        .map((obj) => obj.name)
        .join(" / ");
    },
    isSoccer() {
      if (this.booking === null) {
        return false;
      }
      return this.booking.sport === Sport.SOCCER;
    },
    fixIphoneHeight() {
      this.isIos= false;
      if (AppsHelper.isIOS()) {
        this.isIos= true;
      }
    }
  },
});
</script>

<style scoped>
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.paymentWrapper {
  width: 90%;
  margin: 0 auto;
}
.scoreButtons {
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
}
.customBorder {
  background: linear-gradient(
        var(--background-color-box),
        var(--background-color-box)
      )
      padding-box,
    linear-gradient(60deg, var(--color1), var(--color2)) border-box;
  border: 1px solid transparent;
}

.scoreResult input,
.scoreResult span {
  text-align: center;
  width: 7rem;
  height: 7rem;
}
.scoreScope {
  max-width: 700px;
  margin: 0 auto;
  background-color: var(--background-color);
}

.scoreScope .opacity {
  color: rgba(255, 255, 255, 0.295);
}

.scoreScope .scoreBox {
  border: 1px solid rgba(255, 255, 255, 0.199);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.scoreScopeOpen .scoreBox {
  border: 1px solid rgba(255, 255, 255, 0.199);
}
.scoreScope .scoreName {
  min-width: 180px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .scoreScope .scoreName {
    min-width: 100px;
  }

  .scoreResult input,
  .scoreResult span {
    text-align: center;
    width: 4rem;
    height: 2rem;
  }
}
.scoreScope .borderl {
  border-left: 1px solid rgba(255, 255, 255, 0.164);
}

.scoreScope .borderR {
  border-left: 1px solid rgba(255, 255, 255, 0.164);
}

.scoreScope .noBorderT {
  border-top: none;
  border-top-left-radius: 0;
}

.scoreScope .noBorderB {
  border-bottom-left-radius: 0;
}

.paymentBoxLeft {
  border-radius: 24px;
  /*background: var(--background-color-box);*/
  /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
}
.paymentBoxLeft .custom-gradient {
  border-bottom: 1px solid #ffffff21;
}

.buttomPayMode {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  width: 90%;
}

@media (min-width: 767px) {
  .paymentBoxRight {
    margin: 0 auto;
    /* background: var(--background-color-box);
    box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
  }

  .paymentBoxLeft {
    max-width: 686px;
    margin: 0 auto;
  }

  .buttomPayMode {
    border-radius: 32px;
    width: 327px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
  }
}
</style>